import request, { downloadFile } from "@/utils/axios";

//获取时间范围
export function chanelTimerange() {
  return request.get("/cosmetic/chanel/timerange");
}

//获取品线
export function chanelProductline(params) {
  return request.get("/cosmetic/chanel/productline", params);
}

//获取平台uv 2023-5-25 已作废
// export function chanelPlatformuv(params) {
//   return request.get("/cosmetic/chanel/platformuv", params);
// }

//获取获取人口学画像
export function chanelSocialprofile(params) {
  return request.get("/cosmetic/chanel/socialprofile", params);
}

//获取标签行为画像
export function chanelActionprofile(params) {
  return request.get("/cosmetic/chanel/actionprofile", params);
}

//获取标签内容
export function chanelTagcontent(params) {
  return request.get("/cosmetic/chanel/tagcontent", params);
}

//下载
export function chanelDownloadresult(params) {
  return request.get("/cosmetic/chanel/downloadresult", params);
}

//获取总览部分内容
export function chanelPlatforminfo(params) {
  return request.get("/cosmetic/chanel/platforminfo", params);
}
