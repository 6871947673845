<template>
  <div id="main"></div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import { formatNumberWithCommas } from "@/utils/util";
import * as echarts from "echarts";

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
  },
});

const formatName = (name) => {
  switch (name) {
    case "wechat":
      return "微信";
    case "weibo":
      return "微博";
    case "douyin":
      return "抖音";
    case "xiaohongshu":
      return "小红书";
    case "bilibili":
      return "哔哩哔哩";
    case "Total":
      return "全网";
  }
};


const rich = ref({})

const initChart = (val) => {
  const platform = ["wechat", "weibo", "douyin", "xiaohongshu", "Total"]
  if (props.chartData.length === 6) {
    platform.unshift('bilibili')
  }
  platform.forEach(item => {
    const v = item === 'Total' ? 'network' : item
    rich.value[item] = {
      fontSize: 21,
      width: 21,
      height: 21,
      backgroundColor: {
        image: require(`@/views/sov/sovBrand/sovComponents/platformImg/${v}.png`),
      },
    }
  })
  const chartDom = document.getElementById("main");
  const myChart = echarts.init(chartDom);
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: (params) => {
        const [param] = params;
        return `<p>${formatName(param.name)}</p><p>${param.marker
          }UV：${formatNumberWithCommas(param.value)}</p>`;
      },
    },
    grid: {
      left: "10%",
      right: "26%",
      bottom: "0",
      top: "0",
      containLabel: props.chartData.length === 6 ? false : true,
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
      show: false,
    },
    yAxis: {
      type: "category",
      data: platform,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        align: "right",
        margin: 14,
        formatter: (value) => `{${value}| }`,
        rich: rich.value,
      },
    },
    series: [
      {
        name: "uv",
        type: "bar",
        data: val,
        barWidth: 15,
        label: {
          show: true,
          position: "right",
          formatter: (params) => {
            return formatNumberWithCommas(params.value);
          },
        },
      },
    ],
    color: ["#53BAD2", "#e37893"],
  };

  option && myChart.setOption(option);
};

watch(
  () => props.chartData,
  (val) => {
    initChart(val);
  }
);
</script>

<style lang="scss" scoped>
#main {
  width: 360px;
  height: 160px;
}
</style>
