<template>
  <div>
    <el-table
      class="trend_tracking_table"
      v-loading="loading"
      :data="data"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      :span-method="arraySpanMethod"
    >
      <el-table-column fixed width="350">
        <el-table-column>
          <el-table-column
            width="110"
            prop="cate1"
            align="center"
          ></el-table-column>
          <el-table-column
            width="130"
            prop="cate2"
            align="center"
          ></el-table-column>
          <el-table-column width="110" align="center">
            <template #default="{ row }">
              <div>{{ prefixCheck(row.cate3) }}</div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table-column>
      <el-table-column fixed label="细分维度" width="260" align="left">
        <template #default="{ row }">
          <div @click="tagClick(row)">{{ row.tag }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('xiaohongshu', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">小红书</span>
          </div>
        </template>
        <el-table-column width="80" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel penetration / Benchmark penetration"
                placement="top"
              >
                <span>Tendency TGI</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: row.xhsTgiTendency > 1,
              }"
            >
              {{ row.xhsTgiTendency }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>CHANEL Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.xhsChanelPenetration || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Benchmark社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>Benchmark Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.xhsBenchMarkPenetration || "--" }}</span>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('douyin', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">抖音</span>
          </div>
        </template>
        <el-table-column width="80" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel penetration / Benchmark penetration"
                placement="top"
              >
                <span>Tendency TGI</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: row.dyTgiTendency > 1,
              }"
            >
              {{ row.dyTgiTendency }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>CHANEL Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.dyChanelPenetration || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="" width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Benchmark社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>Benchmark Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.dyBenchMarkPenetration || "--" }}</span>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('wechat', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">微信</span>
          </div>
        </template>
        <el-table-column width="80" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel penetration / Benchmark penetration"
                placement="top"
              >
                <span>Tendency TGI</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: row.wechatTgiTendency > 1,
              }"
            >
              {{ row.wechatTgiTendency }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>CHANEL Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.wechatChanelPenetration || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Benchmark社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>Benchmark Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.wechatBenchMarkPenetration || "--" }}</span>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('weibo', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">微博</span>
          </div>
        </template>
        <el-table-column width="80" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel penetration / Benchmark penetration"
                placement="top"
              >
                <span>Tendency TGI</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: row.weiboTgiTendency > 1,
              }"
            >
              {{ row.weiboTgiTendency }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>CHANEL Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.weiboChanelPenetration || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Benchmark社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>Benchmark Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.weiboBenchMarkPenetration || "--" }}</span>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('total', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">Overall</span>
          </div>
        </template>
        <el-table-column width="80" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel penetration / Benchmark penetration"
                placement="top"
              >
                <span>Tendency TGI</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: row.overallTgiTendency > 1,
              }"
            >
              {{ row.overallTgiTendency }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Chanel社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>CHANEL Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.overallChanelPenetration || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <template #header>
            <div>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Benchmark社媒内容触达人群中，浏览过相应标签内容的人数占比"
                placement="top"
              >
                <span>Benchmark Penetration</span>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.overallBenchMarkPenetration || "--" }}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { sampleUv } from "../utils";
import { Warning } from "@element-plus/icons";

const emits = defineEmits(["dialog"]);
const props = defineProps({
  resourceData: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  sampleList: {
    type: Array,
    default: () => [],
  },
});

const data = ref([]);

const arraySpanMethod = ({ row, rowIndex, columnIndex }) => {
  const { cate1, cate2, cate3 } = row;

  const getCellSpan = (resource, cateProp, cateValue) => {
    const filteredData = resource.filter((i) => i[cateProp] === cateValue);
    const spanCount = filteredData.length;
    const firstRowIndex = props.resourceData.findIndex(
      (i) => i[cateProp] === cateValue
    );

    if (spanCount > 1) {
      if (rowIndex === firstRowIndex) {
        return { rowspan: spanCount, colspan: 1 };
      } else {
        return { rowspan: 0, colspan: 0 };
      }
    } else {
      return { rowspan: 1, colspan: 1 };
    }
  };

  if (columnIndex === 0) {
    return getCellSpan(data.value, "cate1", cate1);
  } else if (columnIndex === 1) {
    return getCellSpan(data.value, "cate2", cate2);
  }
  // else
  if (columnIndex === 2) {
    return getCellSpan(data.value, "cate3", cate3);
  }
};

//表头样式
const headerCellStyle = ({ rowIndex, columnIndex }) => {
  if (rowIndex === 0 && columnIndex === 0) {
    return "border-bottom:0;background-color:#fff;";
  }
  if (rowIndex === 1 && columnIndex === 0) {
    return "background-color:#fff;";
  }
  if (rowIndex === 0 && columnIndex === 1) {
    return "background-color:rgb(246, 248, 250);text-align:center";
  }
  if (rowIndex === 0) {
    return "background-color:#fff;";
  }
  if (rowIndex === 2 || rowIndex === 3) {
    return "display:none";
  }
};

//单元格样式
const cellStyle = ({ row, rowIndex, columnIndex }) => {
  if (columnIndex === 3) {
    return "cursor:pointer";
  }
  if (columnIndex === 2) {
    return "background-color:rgb(246, 248, 250)";
  }

  if (columnIndex > 3) {
    return "padding:0;position:relative";
  }

  if (columnIndex > 2 && rowIndex % 2 !== 0) {
    return "background:#FAFAFA";
  }
  if (rowIndex !== 0) {
    return "padding:4px 0;";
  }
};

const prefixCheck = (str) => {
  if (str.startsWith("empty")) {
    return "--";
  }
  return str;
};

const tagClick = (val) => {
  emits("dialog", val);
};

watch(
  () => props.resourceData,
  (val) => {
    let count = 0;
    data.value = val.map((item) => {
      if (!item.cate3) {
        item.cate3 = `empty${count}`;
        count += !item.cate3;
      } else {
        count++;
      }
      return item;
    });
  }
);
</script>

<style lang="scss" scoped>
@import "../style/table.scss";

::v-deep .el-table--border .el-table__cell .cell {
  padding: 0 6px;
}

.render {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8dde4;
}
</style>
