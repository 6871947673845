<template>
  <div class="chanel-dashboard">
    <el-card>
      <div class="flex_between">
        <div class="flex_start">
          <div class="headline label">品线</div>
          <el-select
            v-model="params.productLine"
            size="small"
            @change="productLineChange"
          >
            <el-option
              v-for="(item, index) in productOptions"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          class="btn-style"
          @click="downLoad"
          >下载</el-button
        >
      </div>
    </el-card>
    <el-card class="overview">
      <template #header>
        <div class="headline flex_between">
          <div>总览</div>
          <div>{{ overview.timePeriod }}</div>
          <div class="blue flex_middle_complete" @click="open">
            <el-icon><Warning /></el-icon>
            <span class="ml-5">查看TA定义</span>
          </div>
        </div>
      </template>
      <div class="flex_start">
        <div class="leftbox flex_start">
          <div class="imgWrapper" style="zoom: 0.5">
            <canvas id="canvas"></canvas>
          </div>
          <div class="numerical-value">
            <div class="reach">
              <div class="characteristic">
                <div class="blue"></div>
              </div>
              <div class="particulars">
                <p>{{ formatNumberWithCommas(overview.totalUv) }}</p>
                <p>触达UV</p>
              </div>
            </div>
            <div class="sample">
              <div class="characteristic">
                <div class="red"></div>
              </div>
              <div class="particulars">
                <p>{{ formatNumberWithCommas(overview.totalSampleUv) }}</p>
                <p>抽样UV</p>
              </div>
            </div>
          </div>
          <div class="describe flex_start">
            <el-icon class="warning-icon"><Warning /></el-icon>
            <span>{{ overview.definition }}</span>
          </div>
        </div>
        <div class="rightbox flex_start">
          <div style="width: 360px">
            <div class="chart-title">触达UV</div>
            <flowChart :chartData="chartData"></flowChart>
          </div>
          <div class="canvasTable">
            <div
              class="chart-title flex_between"
              style="padding: 0 20px; margin-bottom: 1px"
            >
              <span>渗透率</span>
              <span>抽样UV</span>
              <span>发文数</span>
            </div>
            <el-table :data="overview.listPlatformOtherInfo" height="160">
              <el-table-column prop="penetration" align="center" />
              <el-table-column
                prop="platformSampleUv"
                align="center"
                width="140"
              />
              <el-table-column prop="platformPostCount" align="center" />
            </el-table>
          </div>
        </div>
      </div>
    </el-card>
    <el-card>
      <template #header>
        <div class="headline">人口学画像</div>
      </template>
      <demographicTable
        :sampleList="overview.listPlatformOtherInfo"
        :resourceData="socialprofile"
        :loading="loading"
      ></demographicTable>
    </el-card>
    <el-card>
      <template #header>
        <div class="headline">标签行为画像</div>
      </template>
      <labelBehaviorTable
        :sampleList="overview.listPlatformOtherInfo"
        :resourceData="actionprofile"
        :loading="loading"
        @dialog="tagClick"
      ></labelBehaviorTable>
    </el-card>
    <tableDialog
      :dialogVisible="dialogVisible"
      :dialogValue="dialogValue"
      :params="params"
      @close="close"
    ></tableDialog>

    <el-dialog v-model="visibleTA" width="50%" title="TA定义">
      <div>
        <img
          style="width: 100%; height: 100%"
          src="./image/TAdefinition.png"
          fit="cover"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="visibleTA = false">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import {
  chanelTimerange,
  chanelProductline,
  chanelSocialprofile,
  chanelActionprofile,
  chanelDownloadresult,
  chanelPlatforminfo,
} from "@/api/chanelDashboard";
import { formatNumberWithCommas } from "@/utils/util";
import flowChart from "./components/flowChat.vue";
import demographicTable from "./components/demographicTable.vue";
import labelBehaviorTable from "./components/labelBehaviorTable.vue";
import tableDialog from "./components/dialog.vue";
import { Warning } from "@element-plus/icons-vue";

const chartData = ref([]);
const socialprofile = ref([]);
const actionprofile = ref([]);
const productOptions = ref("");
const loading = ref(false);
const dialogVisible = ref(false);
const dialogValue = ref("");
const visibleTA = ref(false);

const overview = ref({
  definition: "",
  listPlatformOtherInfo: [],
  listPlatformUv: [],
  timePeriod: "",
  totalSampleRatio: "",
  totalSampleUv: null,
  totalUv: null,
});

const params = reactive({
  productLine: "",
  timeRange: "",
});

const init = async () => {
  await getTimeRange();
};

const productLineChange = (val) => {
  params.productLine = val;
  getPlatforminfo(params);
};

const tagClick = (val) => {
  dialogVisible.value = true;
  dialogValue.value = val.tag;
};

const close = () => {
  dialogVisible.value = false;
  dialogValue.value = "";
};

//获取时间范围
const getTimeRange = async () => {
  const { data } = await chanelTimerange();
  params.timeRange = data;
  getProductline(data);
};

//获取品线
const getProductline = async (val) => {
  const { data } = await chanelProductline({ timeRange: val });
  productOptions.value = data;
  params.productLine = data[0];
  getPlatforminfo(params);
};

//获取总览部分数据
const getPlatforminfo = async (val) => {
  const { data } = await chanelPlatforminfo(val);
  for (let key in overview.value) {
    overview.value[key] = data[key];
  }
  setChartValue(data.listPlatformUv);
  setTimeout(() => {
    draw(data.totalSampleUv, data.totalUv, data.totalSampleRatio);
  }, 300);
  getSocialprofile(val);
  getActionprofile(val);
};

//获取人口学画像
const getSocialprofile = async (val) => {
  loading.value = true;
  const { data } = await chanelSocialprofile(val);
  socialprofile.value = data;
  loading.value = false;
};

//获取标签行为画像
const getActionprofile = async (val) => {
  loading.value = true;
  const { data } = await chanelActionprofile(val);
  actionprofile.value = data;
  loading.value = false;
};

const draw = (exposureUv, exposure, sampleRatio) => {
  const canvas = document.getElementById("canvas");
  const context = canvas.getContext("2d");

  canvas.width = 300;
  canvas.height = 300;

  context.beginPath();
  context.lineWidth = 40;
  context.strokeStyle = "#53BAD2";
  context.arc(150, 150, 103, 0, 2 * Math.PI);
  context.stroke();
  context.closePath();
  context.beginPath();
  context.lineWidth = 55;
  context.strokeStyle = "#F37092";
  context.globalAlpha = 0.8;
  context.arc(
    150,
    150,
    110,
    (2 - (exposureUv - 0) / (exposure - 0)) * Math.PI,
    (2 * ((exposureUv - 0) / (exposure - 0)) +
      (2 - (exposureUv - 0) / (exposure - 0))) *
    Math.PI
  );
  context.stroke();
  context.closePath();
  context.font = 'bold 34px "微软雅黑"';
  context.textBaseline = "middle";
  context.fillStyle = "#333333";
  context.textAlign = "center";
  context.fillText("抽样率", 150, 130);
  context.fillText(overview.value.totalSampleRatio, 150, 180);
};

const setChartValue = (list) => {
  let sequence = null
  if (list.length === 6) {
    chartData.value = [0, 0, 0, 0, 0, 0];
    sequence = {
      total: 5,
      xiaohongshu: 4,
      douyin: 3,
      weibo: 2,
      wechat: 1,
      bilibili: 0
    };
  } else {
    chartData.value = [0, 0, 0, 0, 0];
    sequence = {
      total: 4,
      xiaohongshu: 3,
      douyin: 2,
      weibo: 1,
      wechat: 0,
    };
  }

  for (let i = 0; i < list.length; i++) {
    const index = sequence[list[i].platform];
    if (index !== undefined) {
      chartData.value[index] = list[i].platformUv;
    }
  }
};

const downLoad = async () => {
  const { data } = await chanelDownloadresult(params);
  window.open(data);
};

//查看TA定义
const open = () => {
  visibleTA.value = true
};

init();
</script>

<style scoped lang="scss">
.chanel-dashboard {
  .btn-style {
    background: #4d7ad7;
    padding: 4px 18px;
    min-height: 30px;
  }
  .flex_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headline {
    max-width: 420px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    .blue {
      font-size: 12px;
      color: #409eff;
      cursor: pointer;
    }
  }

  .label {
    margin-right: 28px;
  }

  .leftbox {
    align-items: start;
    .numerical-value {
      margin-left: 20px;
      .reach,
      .sample {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .characteristic {
          margin-right: 16px;
          div {
            width: 40px;
            height: 12px;
            border-radius: 5px;
          }
          .blue {
            background: #32a3be;
          }
          .red {
            background: #e37893;
          }
        }
        .particulars {
          font-size: 24px;
          font-weight: 700;
          color: #333333;
          p:last-child {
            font-size: 18px;
          }
        }
      }
      .reach {
        margin-bottom: 16px;
      }
    }

    .describe {
      margin-left: 20px;
      position: relative;
      padding: 5px 0 0 20px;
      width: 300px;
      font-size: 16px;
      color: #8c98a5;
      .warning-icon {
        position: absolute;
        left: 0;
        top: 10px;
        font-size: 18px;
      }
      span {
        padding-bottom: 10px;
      }
    }
  }

  .rightbox {
    margin-left: 20px;
    width: 100%;
    .chart-title {
      padding-left: 52px;
      height: 25px;
      font-size: 16px;
      font-weight: 700;
      color: #333333;
    }
    ::v-deep .canvasTable {
      .el-table__header-wrapper {
        display: none;
      }
      table {
        th {
          padding: 0;
          font-size: 16px;
          font-weight: 700;
          color: #333333;
          border-bottom: 0;
        }
        td {
          padding-top: 0;
          padding-bottom: 5px;
          border: 0;
        }
      }
    }
  }

  ::v-deep .el-card {
    margin-bottom: 10px;
  }
  ::v-deep .el-card__header {
    padding-bottom: 0;
    border: 0;
  }
  ::v-deep .el-card__body {
    padding: 8px 20px;
  }

  .overview {
    ::v-deep .el-card__body {
      padding: 0 20px;
    }
  }
}
</style>
