<template>
  <div>
    <el-table
      class="trend_tracking_table"
      v-loading="loading"
      :data="resourceData"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      :span-method="arraySpanMethod"
    >
      <el-table-column>
        <el-table-column>
          <el-table-column width="160" align="center">
            <template #default="{ row }">
              <div class="profileCate1" v-if="row.profileCate1 === 'Income'">
                <span>
                  {{ row.profileCate1 }}
                </span>
                <el-popover placement="right" :width="210" trigger="hover">
                  <div class="income">
                    <p>High （高于254.2k）</p>
                    <p>Middle （100.4k-254.2k）</p>
                    <p>Low（低于100.4k）</p>
                  </div>
                  <template #reference>
                    <el-icon><Warning /></el-icon>
                  </template>
                </el-popover>
              </div>
              <div class="profileCate1" v-else>{{ row.profileCate1 }}</div>
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            prop="profileCate2"
            align="center"
          ></el-table-column>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Overall" align="center">
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Chanel社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">CHANEL</span>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: comparePercentage(
                  row.overallChanelRatio,
                  row.overallBenchMarkRatio
                ),
              }"
            >
              {{ row.overallChanelRatio }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Benchmark社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">Benchmark</span>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.overallBenchMarkRatio }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('xiaohongshu', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">小红书</span>
          </div>
        </template>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Chanel社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">CHANEL</span>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: comparePercentage(
                  row.xhsChanelRatio,
                  row.xhsBenchMarkRatio
                ),
              }"
            >
              {{ row.xhsChanelRatio }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Benchmark社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">Benchmark</span>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.xhsBenchMarkRatio }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('douyin', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">抖音</span>
          </div>
        </template>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Chanel社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">CHANEL</span>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: comparePercentage(
                  row.dyChannelRatio,
                  row.dyBenchMarkRatio
                ),
              }"
            >
              {{ row.dyChannelRatio }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Benchmark社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">Benchmark</span>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.dyBenchMarkRatio }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('wechat', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">微信</span>
          </div>
        </template>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Chanel社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">CHANEL</span>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: comparePercentage(
                  row.wechatChannelRatio,
                  row.wechatBenchMarkRatio
                ),
              }"
            >
              {{ row.wechatChannelRatio }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Benchmark社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">Benchmark</span>
            </div>
          </template>
          <template #default="{ row }">
            <span>{{ row.wechatBenchMarkRatio }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div class="height20 flex_middle_complete">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="sampleUv('weibo', sampleList)"
              placement="top"
            >
              <el-icon><Warning /></el-icon>
            </el-tooltip>
            <span class="ml-5">微博</span>
          </div>
        </template>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Chanel社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">CHANEL</span>
            </div>
          </template>
          <template #default="{ row }">
            <div
              :class="{
                render: comparePercentage(
                  row.weiboChannelRatio,
                  row.weiboBenchMarkRatio
                ),
              }"
            >
              {{ row.weiboChannelRatio }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template #header>
            <div class="height20 flex_middle_complete">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="指定平台/ 跨平台中，Benchmark社媒内容触达人群中，不同画像人群占比"
                placement="top"
              >
                <el-icon><Warning /></el-icon>
              </el-tooltip>
              <span class="ml-5">Benchmark</span>
            </div>
          </template>
          <template #default="{ row }">
            <div>{{ row.weiboBenchMarkRatio }}</div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { sampleUv } from "../utils";
import { Warning } from "@element-plus/icons";
const props = defineProps({
  resourceData: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  sampleList: {
    type: Array,
    default: () => [],
  },
});

const arraySpanMethod = ({ row, rowIndex, columnIndex }) => {
  if (columnIndex === 0) {
    //过滤出一级宣称相同的数据
    const sameRoutIdList = props.resourceData.filter(
      (i) => i.profileCate1 === row.profileCate1
    );
    //找到一级宣称相同数据的第一个下标
    const firstIndex = props.resourceData.findIndex(
      (i) => i.profileCate1 === row.profileCate1
    );
    //合并单元格
    if (sameRoutIdList.length > 1) {
      if (rowIndex === firstIndex) {
        return {
          rowspan: sameRoutIdList.length,
          colspan: 1,
        };
      } else {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    } else {
      return {
        rowspan: 1,
        colspan: 1,
      };
    }
  }
};

//表头样式
const headerCellStyle = ({ rowIndex, columnIndex }) => {
  if (rowIndex === 0 && columnIndex === 0) {
    return "border-bottom:0;background-color:#fff;";
  }
  if (rowIndex === 1 && columnIndex === 0) {
    return "background-color:#fff;";
  }
  if (rowIndex === 0) {
    return "background-color:#fff;font-weight:700;";
  }

  if (rowIndex === 2 || rowIndex === 3) {
    return "display:none";
  }
};

//单元格样式
const cellStyle = ({ row, rowIndex, columnIndex }) => {
  if (columnIndex === 1 && rowIndex !== 0) {
    return "background-color:#F6F8FA;padding:4px 0";
  }
  if (columnIndex === 1) {
    return "background-color:rgb(246, 248, 250)";
  }

  if (columnIndex > 1) {
    return "padding:0;position:relative";
  }

  if (columnIndex > 1 && rowIndex % 2 !== 0) {
    return "background:#FAFAFA";
  }

  if (rowIndex !== 0) {
    return "padding:4px 0";
  }
};

const percentageToDecimal = (percentage) => {
  const numericValue = parseFloat(percentage.replace("%", ""));
  return numericValue / 100;
};

function comparePercentage(percent1, percent2) {
  const num1 = parseFloat(percent1.replace("%", ""));
  const num2 = parseFloat(percent2.replace("%", ""));
  return num1 > num2;
}
</script>

<style lang="scss" scoped>
@import "../style/table.scss";

.profileCate1 {
  font-weight: 600;
  span {
    vertical-align: text-bottom;
    margin-right: 5px;
  }
}

.render {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8dde4;
}
</style>
