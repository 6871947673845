import dayjs from "dayjs";

export function createImage(imgUrl) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      console.log("onload");
      const res = {
        src: imgUrl,
        width: img.width,
        height: img.height,
        aspectRatio: Math.floor((img.height / img.width) * 100) / 100,
      };
      resolve(res);
    };
    img.src = imgUrl;
  });
}

/**
 * 
 * @param {*} val 
 * 首先将整数转换为字符串，然后将字符串倒序，
 * 在第一步中使用正则表达式添加逗号分隔符，
 * 并将字符串再次倒序以将其正确显示。
 * 这种方法避免了在循环中进行字符串操作，
 * 从而提高了性能并减少了代码量 
 */
export const formatNumberWithCommas = (val) => {
  if (val === '--') return val
  const number = parseInt(val);
  const reverseStr = number.toString().split('').reverse().join('');
  const formattedStr = reverseStr.replace(/\d{3}(?=\d)/g, '$&' + ',');
  return formattedStr.split('').reverse().join('');
};

export const timeFormat = (val, type) => {
  return dayjs(val).format(type)
}
