<template>
  <div>
    <el-dialog
      v-model="visible"
      destroy-on-close
      :title="dialogValue"
      width="750px"
      @close="handleClose"
    >
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        :default-sort="{ prop: 'postDate', order: 'descending' }"
        v-loading="loading"
        @sort-change="sortChange"
      >
        <el-table-column prop="platform" width="120" align="center">
          <template #header>
            <el-select
              v-model="tableParams.platform"
              size="small"
              @change="platformChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="kol"
          label="KOL名称"
          align="center"
          width="140"
        />
        <el-table-column
          sortable="custom"
          prop="postDate"
          label="发布时间"
          width="114"
          align="center"
        />
        <el-table-column label="推文链接" width="340" align="center">
          <template #default="{ row }">
            <div class="link" @click="open(row.link)">
              {{ row.link }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-pagination
            v-model:current-page="tableParams.page"
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="currentChange"
          />
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch } from "vue";
import { chanelTagcontent } from "@/api/chanelDashboard";

const emits = defineEmits(["close"]);
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  dialogValue: {
    type: String,
    default: "",
  },
  params: {
    type: Object,
    default: () => {},
  },
});

const total = ref(0);
const tableData = ref([]);
const loading = ref(false);
const visible = ref(false)
const tableParams = reactive({
  productLine: "",
  tag: "",
  platform: "overall",
  timeRange: "",
  page: 1,
  pageSize: 10,
  orderName: "postDate",
  ascDesc: "desc",
});

const getData = async () => {
  loading.value = true;
  tableParams.productLine = props.params.productLine;
  tableParams.timeRange = props.params.timeRange;
  tableParams.tag = props.dialogValue;
  const { data } = await chanelTagcontent(tableParams);
  total.value = data.total;
  tableData.value = data.table;
  loading.value = false;
};

const currentChange = (val) => {
  tableParams.page = val;
  getData();
};

const platformChange = () => {
  tableParams.page = 1;
  getData();
};

const sortChange = ({ column, prop, order }) => {
  if (prop) {
    tableParams.page = 1;
    tableParams.orderName = prop;
    tableParams.ascDesc = order === "descending" ? "desc" : "asc";
    getData();
  }
};

const options = [
  {
    value: "overall",
    label: "全平台",
  },
  {
    value: "xiaohongshu",
    label: "小红书",
  },
  {
    value: "douyin",
    label: "抖音",
  },
  {
    value: "wechat",
    label: "微信",
  },
  {
    value: "weibo",
    label: "微博",
  },
];

//表头样式
const headerCellStyle = ({ rowIndex }) => {
  if (rowIndex === 0) {
    return "background-color:#F6F8FA;";
  }
};

const handleClose = () => {
  tableParams.page = 1;
  tableParams.platform = "overall";
  emits("close");
};

const open = (val) => {
  window.open(val, "_blank");
};

watch(
  () => props.dialogValue,
  (val) => {
    if (props.dialogVisible && val) {
      getData();
    }
  }
);

watch(
  () => props.dialogVisible,
  (val) => {
    visible.value = val
  }
)
</script>


<style lang="scss" scoped>
.link {
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #409eff;
  cursor: pointer;
}
.el-pagination {
  text-align: center;
}
</style>
